enum TextureKeys {
    Theme = 'youngcircle',
    Slotmachine = 'slotmachine.png',
    Symbols = 'symbols',
    Griff = 'griff/griff1.png',
    Logo = 'yclogo.png',
    Title = 'summergambletitle.png'
}

export default TextureKeys
