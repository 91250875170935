const contestForm = () => {
    return (
        <div>
            <div class="columns">
                <div class="column">
                        <div class="field">
                            <label class="label is-large">Lösungswort</label>
                            <div class="control">
                                <input class="input is-large" type="text" name="book" placeholder="Lösungswort"/>
                            </div>
                            <p id="bookhelp" class="help is-danger">&nbsp;</p>
                        </div>
                        <div class="field">
                            <label class="label is-large">Email</label>
                            <div class="control">
                                <input class="input is-large" type="email" name="email" placeholder="Email"/>
                            </div>
                            <p id="mailhelp" class="help is-danger">&nbsp;</p>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button class="button is-link is-large is-primary" name="send">Teilnehmen</button>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    )
}

export default contestForm
