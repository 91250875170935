import ElementKeys from '~/consts/ElementKeys'
import {PhaserGameHeight, PhaserGameWidth} from '~/utils/Resolution';
import WebfontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';

const config: Phaser.Types.Core.GameConfig = {
    type: Phaser.CANVAS,
    parent: ElementKeys.ContainerId,
    transparent: true,
    dom: {
        createContainer: true
    },
    plugins: {
        global: [
            {
                key: 'rexWebfontLoader',
                plugin: WebfontLoaderPlugin,
                start: true
            }
        ]
    },
    scale: {
        mode: Phaser.Scale.ScaleModes.FIT,
        autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
        width: PhaserGameWidth,
        height: PhaserGameHeight
    },

}

export default config
