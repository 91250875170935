const firebaseConfig = {
    apiKey: "AIzaSyDV5SZgkpAm-3_fxL99rU-mQE1AKCac9K8",
    authDomain: "youngcircle-62b4f.firebaseapp.com",
    databaseURL: "https://youngcircle-62b4f.firebaseio.com",
    projectId: "youngcircle-62b4f",
    storageBucket: "youngcircle-62b4f.appspot.com",
    messagingSenderId: "971985677815",
    appId: "1:971985677815:web:a39e3e4ce775738ae312f2",
    measurementId: "G-WP53TP7FFZ"
};

export default firebaseConfig;