import Phaser from 'phaser'
import TextureKeys from '~/consts/TextureKeys'
import {assetsDPR} from '~/utils/Resolution';
import GameEvents from '~/consts/GameEvents';
import GameData from '~/consts/GameData';

export default class Slot extends Phaser.GameObjects.TileSprite {
    private _id!: number
    private targetSymbol: number

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x * assetsDPR, y * assetsDPR, 0, 0, TextureKeys.Symbols)
        this.targetSymbol = -1;
        this.tilePositionX = 0;
        this.tilePositionY = (1 - GameData.startingSymbol) * this.displayHeight / GameData.totalSymbols; // convert startingSymbol to correct tilePosition
        const shape = scene.make.graphics({});
        shape.fillStyle(0xffff00, 1);
        shape.beginPath()
        shape.fillRoundedRect(this.x - 175 * assetsDPR / 4, this.y - 600 * assetsDPR / 4, 342 * assetsDPR / 4, 458 * assetsDPR / 4, 20 * assetsDPR / 4);
        const mask = shape.createGeometryMask();
        this.setMask(mask);
        scene.add.existing(this);
    }

    public spinStart() {
        this.scene.tweens.add({
            targets: this,
            tilePositionY: this.tilePositionY + this.displayHeight,
            duration: 1500,
            ease: 'Back.easeIn',
            onComplete: () => {
                this.spinLong()
            },
            onCompleteScope: this
        });
    }

    private spinLong() {
        this.scene.tweens.add({
            targets: this,
            tilePositionY: this.tilePositionY + this.displayHeight,
            duration: 500,
            ease: 'Linear',
            loop: 2,
            onComplete: () => {
                this.spinEnd()
            },
            onCompleteScope: this
        });
    }

    private spinEnd() {
        const targetY = (2 - this.targetSymbol) * this.displayHeight / GameData.totalSymbols + this.displayHeight;
        this.scene.tweens.add({
            targets: this,
            tilePositionY: targetY,
            duration: 2500,
            ease: 'Back.easeOut',
            onComplete: () => {
                this.emit(GameEvents.SlotmachineFinished);
            },
            onCompleteScope: this
        });
    }

    setTarget(id: number) {
        this.targetSymbol = id % GameData.totalSymbols + 1;
        return this;
    }

    setID(id: number) {
        this._id = id;
        return this;
    }
}