import Phaser from 'phaser'
import {assetsDPR} from '~/utils/Resolution';

export default class RetinaSprite extends Phaser.GameObjects.Sprite {
    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture, frame?: string | integer) {
        super(scene, x * assetsDPR, y * assetsDPR, texture, frame)
        scene.add.existing(this)
    }

    setX(x) {
        super.setX(Math.round(x * assetsDPR))
    }

    setY(y) {
        super.setY(Math.round(y * assetsDPR))
    }

    get retinaX () {
        return this.x / assetsDPR;
    }

    get retinaY () {
        return this.y / assetsDPR;
    }
}