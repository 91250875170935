import Phaser from 'phaser'
import TextureKeys from '~/consts/TextureKeys'
import GameEvents from '~/consts/GameEvents'
import {assetsDPR} from '~/utils/Resolution';
import SceneKeys from '~/consts/SceneKeys';

export default class Preload extends Phaser.Scene {
    constructor() {
        super(SceneKeys.Preload);
    }

    preload() {
        this.load.path = 'assets/atlas/';
        this.load.multiatlas(TextureKeys.Theme, `yc@${assetsDPR}.json`)
    }

    create() {
        const frameNames = this.anims.generateFrameNames(TextureKeys.Theme, {
            start: 1, end: 4,
            prefix: 'griff/griff', suffix: '.png'
        });
        this.anims.create({
            key: 'pull',
            frameRate: 16,
            frames: frameNames,
            repeat: 0,
            yoyo: true
        })
        this.game.events.emit(GameEvents.PreloadFinished)
    }
}
