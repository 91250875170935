import button from '~/ui/Button';

const explainer = () => {
    return (
        <div>
            <div class="columns">
                <div class="column">
                    <div class="explainer px-6">
                        <p>Gewinne täglich coole Preise für den Schulstart und um den Lesesommer noch etwas zu
                            verlängern!</p>
                        <button id="play" class="button is-primary is-large is-centered">
                            Let's Play!
                        </button>
                        <p>Wie funktioniert’s?</p>
                        <ol style="margin-bottom: 3rem">
                            <li>Zieh den Hebel unserer Emoji-Slot-Machine!</li>
                            <li>Errate den Buchtitel, den die drei Emojis dir anzeigen.</li>
                            <li>Du errätst das Lösungswort nicht? Klicke auf Help!
                            </li>
                            <li>Gib uns deine E-Mail-Adresse an, damit wir dich kontaktieren können, wenn du gewinnst.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default explainer








