const thankyou = () => {
    return (
        <div>
            <div class="columns">
                <div class="column">
                    <div class="thankyou px-6">
                        <p>Vielen Dank für's Mitmachen. Wenn du gewonnen hast, informieren wir dich per E-Mail.</p>
                        <p>Schau morgen wieder vorbei und löse unser nächstes Emoji-Rätsel.</p>
                        <a class="button is-primary is-large" href="https://youngcircle.ch">
                            Zum Blog
                        </a>
                        <p>
                            Du willst mehr zum Young Circle von Orell Füssli erfahren, tolle Buchtipps erhalten und von
                            einem
                            Dauerrabatt von 10% auf das ganze Sortiment profitieren? Folge uns jetzt auf <a
                            href="https://www.instagram.com/youngcircle/?hl=de">Instagram</a> oder <a
                            href="https://youngcircle.ch/infos/">werde
                            kostenlos Young Circle Member!</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default thankyou



