import Phaser from 'phaser'
import SceneKeys from './consts/SceneKeys'
import registerScenes from './registerScenes'
import * as React from 'jsx-dom'
window['React'] = React

import config from './config'

const game = new Phaser.Game(config)

registerScenes(game)

game.scene.start(SceneKeys.Bootstrap)
