import SceneKeys from '~/consts/SceneKeys'

import Bootstrap from './scenes/Bootstrap'
import Preload from './scenes/Preload'
import Game from './scenes/Game'
import Contest from './scenes/Contest'
import Explainer from '~/scenes/Explainer';

const registerScenes = (game: Phaser.Game) => {
    const scene = game.scene
    scene.add(SceneKeys.Bootstrap, Bootstrap)
    scene.add(SceneKeys.Preload, Preload)
    scene.add(SceneKeys.Explainer, Explainer)
    scene.add(SceneKeys.Game, Game)
    scene.add(SceneKeys.Contest, Contest)
}

export default registerScenes
