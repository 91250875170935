import Phaser from 'phaser'
import RetinaText from '~/classes/RetinaText';
import {gameWidth} from '~/utils/Resolution';
import SceneKeys from '~/consts/SceneKeys';
import RetinaDOMElement from '~/classes/RetinaDOMElement';
import ContestForm from '~/ui/ContestForm';
import {campaignRef} from '~/utils/Firebase';
import thankyou from '~/ui/Thankyou';
import RetinaSprite from '~/classes/RetinaSprite';
import TextureKeys from '~/consts/TextureKeys';
import {validateEmail} from '~/utils/Validation';

export default class Contest extends Phaser.Scene {
    private contestForm?: RetinaDOMElement
    private thankyouText?: RetinaDOMElement
    private text?: RetinaText

    constructor() {
        super(SceneKeys.Contest);
    }

    create() {
        const logo = new RetinaSprite(this, gameWidth / 2, 200, TextureKeys.Theme, TextureKeys.Title).setOrigin(0.5, 1);

        this.thankyouText = new RetinaDOMElement(this, gameWidth / 2, logo.retinaY + 20, thankyou() as HTMLElement)
            .setOrigin(0.5, 0).setAlpha(0);


        this.contestForm = new RetinaDOMElement(this, gameWidth / 2, logo.retinaY + 20, ContestForm() as HTMLElement)
            .addListener('click').on('click', (event) => {
                let bookhelp = document.getElementById('bookhelp');
                let mailhelp = document.getElementById('mailhelp');
                if (this.contestForm && event.target.name === 'send') {
                    let book = this.contestForm.getChildByName('book') as HTMLInputElement;
                    let email = this.contestForm.getChildByName('email') as HTMLInputElement;
                    //  Have they entered anything?
                    if (book.value !== '' && email.value !== '' && validateEmail(email.value)) {
                        //  Turn off the click events
                        this.contestForm.removeListener('click');
                        const item = {
                            book: book.value,
                            email: email.value,
                            createdAt: (new Date()).toISOString()
                        };
                        campaignRef.add(item)      // doc: { ... }
                            .then(function (doc) {
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                        this.add.tween({
                            targets: this.contestForm,
                            alpha: 0,
                            duration: 500
                        })
                        this.add.tween({
                            targets: this.thankyouText,
                            alpha: 1,
                            duration: 500,
                            offset: 500,
                        })
                    } else {
                        if (bookhelp && mailhelp) {
                            bookhelp.innerHTML = "&nbsp;";
                            mailhelp.innerHTML = "&nbsp;";
                            if (book.value === '') {
                                bookhelp.innerText += 'Lösungswort fehlt.'
                            }
                            if (email.value === ''||  !validateEmail(email.value)) {
                                mailhelp.innerText += 'E-Mail ist ungültig.'
                            }
                        }
                    }
                }
            }, this)
        this.contestForm.setOrigin(0.5, 0);

        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.handleShutdown()
        })
    }

    private handleShutdown() {

    }

}