import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import firebaseConfig from './FirebaseConfig'
// Your web app's Firebase configuration

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebaseApp.analytics();
const db = firebaseApp.firestore();
export const campaignRef = db.collection('campaign202106');

export default firebase;