import Phaser from 'phaser'
import {gameWidth} from '~/utils/Resolution';
import SceneKeys from '~/consts/SceneKeys';
import RetinaDOMElement from '~/classes/RetinaDOMElement';
import explainer from '~/ui/Explainer';
import RetinaSprite from '~/classes/RetinaSprite';
import TextureKeys from '~/consts/TextureKeys';

export default class Explainer extends Phaser.Scene {
    private explainerText?: RetinaDOMElement

    constructor() {
        super(SceneKeys.Explainer);
    }

    create() {
        const logo = new RetinaSprite(this, gameWidth / 2, 200,TextureKeys.Theme, TextureKeys.Title).setOrigin(0.5,1);


        this.explainerText = new RetinaDOMElement(this, gameWidth / 2, logo.retinaY+20, explainer() as HTMLElement)
            .setOrigin(0.5, 0).addListener('click').on('click', (event) => {
                if(event.target.id == 'play') {
                    this.explainerText?.setVisible(false);
                    this.scene.start(SceneKeys.Game)
                }
        })

        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.handleShutdown()
        })
    }

    private handleShutdown() {

    }

}
