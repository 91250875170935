enum SceneKeys
{
    Bootstrap = 'bootstrap',
    Preload = 'preload',
    Explainer = 'explainer',
    Game = 'game',
    Contest = 'contest'
}

export default SceneKeys
