import Phaser, {Scene} from 'phaser'
import GameData from '~/consts/GameData'
import SceneKeys from '~/consts/SceneKeys'
import GameEvents from '~/consts/GameEvents'
import RetinaText from '~/classes/RetinaText';
import {assetsDPR, gameHeight, gameWidth} from '~/utils/Resolution';
import TextureKeys from '~/consts/TextureKeys';

export default class Bootstrap extends Phaser.Scene {
    constructor() {
        super(SceneKeys.Bootstrap);
    }

    preload() {
        // @ts-ignore
        this.load.rexWebFont({
            custom: {
                families: ['Crewniverse', 'GillSans'],
                urls: ['assets/fonts/font.css']
            }
        });
        this.game.events.once(GameEvents.PreloadFinished, this.handlePreloadFinished, this)
    }

    create() {
        this.scene.run(SceneKeys.Preload)
        new RetinaText(this, gameWidth / 2, gameHeight / 2, 'Loading...', {
            fontFamily: 'Crewniverse',
            fontSize: 14
        }).setOrigin(0.5, 0.5);
    }

    private handlePreloadFinished() {
        this.scene.stop(SceneKeys.Preload)
        // Dynamically generate TilespriteTexture width@4 256 with 21 icons
        const retinaWidth = 256 / 4 * assetsDPR;
        const retinaHeight = 256 * GameData.totalSymbols / 4 * assetsDPR;

        const symbols = this.make.renderTexture({width: retinaWidth, height: retinaHeight}, false);
        for (let i = 0; i < GameData.totalSymbols; i++) {
            const zeropad = ('00' + i).slice(-3);
            symbols.drawFrame(TextureKeys.Theme, `symbols/${zeropad}.png`, 0, retinaHeight - i * retinaWidth - retinaWidth);
        }
        symbols.saveTexture(TextureKeys.Symbols);

        //console.log('preload finished')
        this.scene.start(SceneKeys.Explainer)
    }
}
