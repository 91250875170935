/**
 * The base resolution of this game is    414x736 @4.
 * Means you should draw all your assets in a resolution of 1656x2944
 * and pack and scale them down with a TexturePacker like https://www.codeandweb.com/texturepacker
 */

export const gameWidth = 414
export const gameHeight = 896
const roundHalf = num => Math.round(num * 2) / 2

const graphicsSettings = {best: 1, medium: 0.75, low: 0.5}
const DPR = window.devicePixelRatio * graphicsSettings.best

/**
 * Need a fixed screen ratio for your game
 * because it is to hard to make it responsive?
 * Simply lock the screen ratio by adapting the code as follow.
 * (On a desktop PC it should show all assets in @4)
 *
 * WIDTH: Resulting Phaser Game Width
 * On mobile it will be gameWidth * DPR on Desktop gameWidth x 4 = 1656x3584
 * DPR:
 * iPhone 6 Plus, 6s Plus, 7 Plus, 8 Plus, iPhone 11 Pro, X, XS, iPhone 11 Pro Max, XS Max: 3
 * iPhone 4, 4s, iPhone 5, 5c, 5s, SE, iPhone 6, 6s, 7, 8: 2
 * iPhone 2G, 3G, 3GS: 1
 * all iPads except iPad mini, iPad, iPad 2: 2
 *
 * HEIGHT: Resulting Phaser Game Height
 */

const isMobile = () => Math.min(window.screen.width, window.screen.height) <= 480
export const PhaserGameWidth = gameWidth * (isMobile() ? DPR : 4)
export const PhaserGameHeight = gameHeight * (isMobile() ? DPR : 4)

// Which assetsDPR should be chosen: 1, 1.5, 2, 2.5, 3, 3.5 or 4
export const assetsDPR = roundHalf(Math.min(Math.max(PhaserGameHeight / gameHeight, 1), 4))

/*
console.log('DPR = ', DPR)
console.log('assetsDPR = ', assetsDPR)
console.log('assetsDPR based on width: ', roundHalf(Math.min(Math.max(PhaserGameWidth / gameWidth, 1), 4)))
console.log('WIDTH = ', PhaserGameWidth)
console.log('HEIGHT = ', PhaserGameHeight)
*/
