import Phaser from 'phaser'
import {assetsDPR} from '~/utils/Resolution';

export default class RetinaText extends Phaser.GameObjects.Text {
    constructor(scene: Phaser.Scene, x: number, y: number, text: string, style: any) {
        if (style.fontSize){
            style.fontSize *= assetsDPR
        }
        super(scene, x * assetsDPR, y * assetsDPR, text, style);
        scene.add.existing(this)
    }
}